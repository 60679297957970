.main{
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  ._header{
    width: 100%;
    height: 36%;
    max-height: 36%;
  }

  ._body{
    height: 64%;
    max-height: 64%;
    overflow-y: scroll;
    box-sizing: border-box;
    width: 100%;
      padding: 20px 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap:20px;
      overflow-y: scroll;
      overflow-x: hidden;
      .__description{
        display: none;
      }
  
      img{
        width: 150px;
      }
  
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
  
      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #a19b9b;
      }
      .video{
        width: 800px;
        height: 500px;
      }
  }
  
}

@media screen and (max-width: 915px) {

  .main{
  max-height: calc(100vh - 75px) ;


    ._header{
      width: 100%;
      height: 33%;
      max-height: 33%;
    }
  
    ._body{
      height: 67%;
      max-height: 67%;
      padding: 10px;

      .__description{
        display: block;
        margin: 0;
        text-align: center;
      }
      .video{
        width: 300px;
        height: 200px;
      }
    }
  }

 

}