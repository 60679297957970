.main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;

    label{
        width: 30%;
        font-size: 16px;
        margin-top: 5px;
    }


    .inputs{
        width: 70%;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        .prefisso{
            width: 40px;
            padding: 0 10px;
        }

        .wrapper{
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .errorMessage{
                margin: 0;
                font-size: 10px;
                    color: #990000
            }

            .noErrorMessage{
                margin: 0;

                visibility: hidden;
            }
        }
    
        input{
            width: 100%;
            height: 38px;
            border-width: 0.5px;
            border-radius: 6px;
            padding: 0 10px;
        }
    }
    
}


@media screen and (max-width: 915px) {
    .main{
        box-sizing: border-box;

        label{
            // width: 80px;
        }

       .inputs{
        width: 100%;

        .prefisso{
            width: 30px;
        }

        
        
       }
        
    }

}