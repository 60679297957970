.main {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  background-color: #0069ca;
  padding: 10px 2px 10px 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  

  img{
    width: 100px;
  }

  ._camelotLogo {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  ._title {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .__firstWrap {
      display: flex;
      justify-content: flex-start;
      gap: 30px;
      

      .__titleText {
        line-height: 1;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;

        :nth-child(1) {
          font-size: 40px;
          margin: 0;
          padding: 0;
        }

        :nth-child(2) {
          font-size: 26px;
          margin: 0;
          padding: 0;
        }
      }
    }


    .right{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 10px;

      img{
        width: 150px;
      }

    .__tag {
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;
      border-radius: 10px 0 0 10px;
      font-size: 16px;
      font-weight: 800;
      color: #0069ca;
      padding: 15px 20px;

    }
  }

  }
  .logoQuartieriMobile{
    display: none;
  }
  ._description {
    padding: 0 20px 0 0;
    color: white;
    font-weight: 500;
  }
}

@media screen and (max-width: 915px) {
  .main {
  padding: 10px 10px 10px 20px;
  gap: 10px;
  height: 100%;

    ._camelotLogo {
      padding-right: 0;
      img {
        width: 60px;
      }
    }

    ._title {
      .__firstWrap {
        gap: 23px;
        img {
          height: 100px;
        }

        .__titleText {


          :nth-child(1) {
            font-size: 20px;
            margin: 0;
            padding: 0;
          }

          :nth-child(2) {
            font-size: 12px;
            margin: 0;
            padding: 0;
          }
        }
      }

      .right{
        img {
          display: none;
        }
        .__tag {
          display: none;
        }
      }

      
    }
    .logoQuartieriMobile{
      
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
  
    }
  }
  
  

  ._description {
    display: none;
    // height: 50px;
    // overflow-y: scroll;
    // padding: 0 20px 0 0;
    // color: white;
    // font-weight: 500;
    // font-size: 12px;
  }
}
