.mainForm{
    width: 60%;
    min-width: 50%;
    background-color: #F5F5F5;
    border-radius: 10px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .inputs{
        display: grid;
        grid-template-columns: 45% 45%;
        gap: 40px;
        row-gap: 8px;
    }

    .privacy{
        .warning{
            font-style: italic;
            color: #8F8F8F;
            margin: 0;
        }
    }

    .confirm{
        display: flex;
        justify-content: flex-end;
    }
}


@media screen and (max-width: 915px) {
    .mainForm{
        width: 90%;
        padding: 20px;
        
        .inputs{
            display: flex;
            flex-direction: column;
            gap:20px;
        }

        .privacy{
            .warning{
                font-size: 14px;
                font-weight: 600;
            }
        }

        .confirm{
            display: flex;
            justify-content: center;
        }
    }
}